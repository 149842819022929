import { Button, Col, Form, Input, message, notification, Row } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "antd/dist/antd.css";
import "./styles/main.scss";

import emailjs from "@emailjs/browser";

import Slider from "react-slick";
import { useWidth } from "./utils/use-is-mobile";
import React, { useState, useEffect, useRef } from 'react';
const { TextArea } = Input;

function App() {
  const { isMobile } = useWidth();
  var Setting = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    // afterChange: (current) => setCurrentSlide(current),
  };
  var Setting2 = {
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    // afterChange: (current) => setCurrentSlide(current),
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onFinish = (e: any) => {
    setIsLoading(true);
    emailjs
      .send(
        "service_0wn65rm",
        "template_o6sncr8",
        {
          from_name: e.fullname,
          email: e.email,
          subject: e.subject,
          message: e.message,
        } as any,
        "ocEgO3lYXcDj0_KO0"
      )
      .then(
        (result) => {
          setIsLoading(false);
          notification.open({
            type: "success",
            message: "Message sent successfully",
            style: { backgroundColor: "#fbfbfb" },
          });
          form.resetFields();
          // console.log(result.text);
        },
        (error) => {
          setIsLoading(false);
          notification.open({
            type: "error",
            message: "Failed to send message",
            style: { backgroundColor: "#fbfbfb" },
          });
          // console.log(error.text);
        }
      );
  };


  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [scrollDirection, setScrollDirection] = useState("none");
  useEffect(() => {
    let prevScrollPos = window.pageYOffset;
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollPos > currentScrollPos) {
        setScrollDirection("up");
      } else if (prevScrollPos < currentScrollPos) {
        setScrollDirection("down");
      }
      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (

    <div className="App">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"></link>

      <div className="homepage">

        {/* ======================= header section ======================= */}

        <div className="intro">
          <header className={`header ${scrollDirection === "down" ? "hidden" : ""} ${isScrolled ? 'scrolled' : ''}`}>
            <div className="logo">
              <img src="/images/logo.png" alt="" />
            </div>

            <div className="navigation">
              <div className={`nav-items ${menuOpen ? 'show' : ''}`}>
                <div className="nav-close-btn" onClick={closeMenu}><i className="fa fa-times" aria-hidden="true"></i></div>
                <a className="active" href="#home" onClick={closeMenu}>Home</a>
                <a href="#about" onClick={closeMenu}>About</a>
                <a href="#services" onClick={closeMenu}>Services</a>
                <a href="#leed " onClick={closeMenu}>Leed</a>
                <a href="#partners " onClick={closeMenu}>Partners</a>
                <a href="#contact " onClick={closeMenu}>Contact</a>
              </div>
            </div>
            <div className="nav-menu-btn" onClick={toggleMenu}><i className="fa-solid fa-bars"></i></div>
          </header>

          {/* ======================= intro section ======================= */}

          <section className="introCon flex-center" id="home">
            <div className="intro-container">

              <div className="media-icons">
                <a href="https://whatsapp.com/channel/0029Va9K4iuGzzKSdOjpuf2a"><i className="fab fa-whatsapp"></i></a>
                <a href="https://www.facebook.com/people/SafInnovation/100086740831039"><i className="fab fa-facebook"></i></a>
                <a href="https://instagram.com/safinnovation"><i className="fab fa-instagram"></i></a>
              </div>

              <div className="info">
                <h2>
                  Our services are to help <br /> you connect to your
                  <br />
                  project virtually
                </h2>
                <p>
                  Documenting and follow up on the project using 360
                  photos and aerial photos. <br /> Those different methods are
                  used to ensure a unique and luxurious virtual look of
                  your projects.
                </p>
              </div>

              <div className="home-img">
                <img src="/images/logo1.png"
                  alt=""
                  className="logo1" />
              </div>
            </div>
            <a href="#about" className="scroll-down">Scroll Down <i className="fas fa-arrow-down"></i></a>
          </section>
        </div>

        {/* ======================= about section ======================= */}

        <section className="about" id="about">
          <div className="container section flex-center">
            <h1 className="section-title-01">About Us</h1>
            <h2 className="section-title-02">About Us</h2>
            <div className="content flex-center">
              <div className="about-img">
                <img src="/images/21.jpg" alt="" />
              </div>
              <div className="about-info">
                <div className="description">
                  <h3>We Are The Leaders Of Construction</h3>
                  <p>At Safinnovation, we are at the forefront of the construction industry, leading the way in the innovative realm of digital twinning. With a global presence, including offices in Canada and Dubai, our vision transcends the boundaries of traditional project management.
                    We envision a next-generation paradigm, where all project components seamlessly converge into a unified digital realm. This convergence empowers our clients with unprecedented accessibility, offering them a single entry point to their projects, revolutionizing how they interact with their construction endeavors.
                  </p>
                  <h3>Our Commitment</h3>
                  <p>
                    We are not just documenting projects; we are redefining the way construction projects are managed. Our tools and services are designed to facilitate easy project monitoring, streamline decision-making, and enhance the overall construction experience.
                    At Safinnovation, we are not just leaders; we are the architects of the future of construction. Join us on this exciting journey as we transform the industry, one project at a time.</p>
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* ======================= services section ======================= */}


        <section className="ourServices section container flex-center" id="services">
          <h1 className="section-title-01">Our Services</h1>
          <h2 className="section-title-02">Our Services</h2>
          <Row justify="center">
            <Col span={22}>
              <Row gutter={[80, 60]}>
                <Col order={1} lg={{ span: 12, order: 0 }}>
                  <div className="ourServicesImg">
                    <img src="/images/31.jpg" alt="" />
                  </div>
                </Col>
                <Col order={0} lg={{ span: 12, order: 1 }}>
                  <div className="ourServicesText">
                    <div className="title">
                      Valuing and enriching the BIM system of your project
                    </div>
                    <div className="des">
                      Our services will add big value to the project by
                      maintaining the BIM system while the project is undergoing
                      and even in the design phases of the project.
                    </div>
                  </div>
                </Col>
                <Col order={2} span={24}>
                  <Row gutter={[80, 40]}>
                    <Col lg={8}>
                      <div className="introCard">
                        <img
                          src="/images/icon31.png"
                          alt=""
                          className="cardImg"
                        />
                        <div className="cardTitle">3D Model</div>
                        <div className="cardDes">
                          The 3D model is our guide to ensure a precise and
                          accurate delivery of the project.
                        </div>
                      </div>
                    </Col>
                    <Col lg={8}>
                      <div className="introCard">
                        <img
                          src="/images/icon32.png"
                          alt=""
                          className="cardImg"
                        />
                        <div className="cardTitle">Drone</div>
                        <div className="cardDes">
                          Aerial photos can ensure a good excavation. Aerial
                          photos, our methods can generate 2D/3D model of the
                          site that will facilitate an easier volume and area
                          calculations.
                        </div>
                      </div>
                    </Col>
                    <Col lg={8}>
                      <div className="introCard">
                        <img
                          src="/images/icon33.png"
                          alt=""
                          className="cardImg"
                        />
                        <div className="cardTitle">360 Photos</div>
                        <div className="cardDes">
                          View the project anywhere with your own device. Walk
                          inside the site virtually and see every detail also
                          add comments and notes if needed.
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>

        {/* ======================= dron section ======================= */}

        <section className="drone">
          <Row>
            <Col lg={{ span: 16, offset: 8 }}>
              <div className="droneBox">
                <div className="droneText">
                  <div className="subtitle">Drone</div>
                  <div className="title">
                    We Turn Ideas Into Works Of Art
                  </div>
                  <div className="des">
                    Aerial photos can ensure a good excavation. Aerial photos,
                    our methods can generate 2D/3D model of the site that will
                    facilitate an easier volume and area calculations.
                  </div>
                  <Slider className="DroneSlider" {...Setting}>
                    <div className="silderCard">
                      <img src="/images/41.jpg" alt="" />
                    </div>
                    <div className="silderCard">
                      <img src="/images/42.jpg" alt="" />
                    </div>
                    <div className="silderCard">
                      <img src="/images/43.jpg" alt="" />
                    </div>
                    <div className="silderCard">
                      <img src="/images/44.jpg" alt="" />
                    </div>
                  </Slider>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        {/* ======================= 360 photo section ======================= */}


        <section className="aboutUs">
          <Row justify="center">
            <Col span={22}>
              <Row gutter={[110, 40]}>
                <Col lg={10}>
                  <div className="aboutUsText">
                    <div className="subtitle">360 Photos</div>
                    <div className="title">
                      Walk the site virtually from the comfort of your office
                    </div>
                    <div className="des">
                      View the project anywhere with your own device. Walk
                      inside the site virtually and see every detail also add
                      comments and notes if needed.
                    </div>
                    <Slider className="DroneSlider" {...Setting2}>
                      <div className="silderCard">
                        <img src="/images/51.jpg" alt="" />
                      </div>
                      <div className="silderCard">
                        <img src="/images/52.jpg" alt="" />
                      </div>
                      <div className="silderCard">
                        <img src="/images/53.jpg" alt="" />
                      </div>
                      <div className="silderCard">
                        <img src="/images/54.jpg" alt="" />
                      </div>
                    </Slider>
                  </div>
                </Col>
                <Col span={24} lg={14}>

                  <div className="aboutUsVideo">
                    <iframe
                      width="100%"
                      height="390"
                      src="https://www.youtube.com/embed/swOZ_LhJHJo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>

        {/* ======================= 3d model section ======================= */}

        <section className="model">
          <Row justify="center">
            <Col span={22}>
              <Row style={{ height: "100%" }} gutter={[40, 40]}>
                <Col lg={{ span: 15 }}>
                  <div className="droneText">
                    <div className="subtitle">3D Model</div>
                    <div className="title">
                      We Turn Ideas Into Works Of Art
                    </div>
                    <div className="des">
                      The 3D model is our guide to ensure a precise and accurate
                      delivery of the project.
                    </div>
                    <Slider className="DroneSlider" {...Setting}>
                      <div className="silderCard">
                        <img src="/images/61.jpg" alt="" />
                      </div>
                      <div className="silderCard">
                        <img src="/images/64.jpg" alt="" />
                      </div>
                      <div className="silderCard">
                        <img src="/images/63.jpg" alt="" />
                      </div>
                      {/* <div className="silderCard">
                        <img src="/images/64.jpg" alt="" />
                      </div> */}
                    </Slider>
                  </div>
                </Col>
                <Col lg={9}>
                  <div className="imgModal">
                    <img src="/images/62.jpg" alt="" />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>

        {/* ======================= leed section ======================= */}

        <section className="leed" id="leed">
          <div className="container section flex-center">
            <h1 className="section-title-01">Leed Assessment</h1>
            <h2 className="section-title-02">Leed Assessment</h2>
            <div className="content flex-center">
              <div className="leed-info">
                <div className="description">
                  <p>We aim, At Safinnovation, our commitment to sustainable practices and adherence to green building standards align with the principles of our projects.</p>
                  <hr />
                  <p>Leadership in Energy and Environmental Design (LEED). This globally recognized green building certification program, established by the non-profit organization, the U.S. Green Building Council, offers a comprehensive array of rating systems. These systems cover aspects ranging from the planning and construction phases to the operation and maintenance of eco-friendly buildings, residences, and communities.
                    Our objective is to support building owners and operators in their pursuit of environmental responsibility and resource efficiency. By adhering to LEED guidelines, we strive to ensure that our projects not only meet the highest standards of sustainability but also contribute to a greener and more environmentally conscious future.</p>
                </div>
              </div>
              <div>
                <div className="leed-img">
                  <img src="/images/71.jpg" alt="" />
                </div>
                <div className="logo-leed">
                  <img src="/images/saudi.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ======================= partners section ======================= */}


        <section className="partners" id="partners">
          <div className="blur-overlay"></div>
          <div className="container section flex-center">
            <h1 className="section-title-01">Our Partners</h1>
            <h2 className="section-title-02">Our Partners</h2>

            <div className="content flex-center">
              <div className="partners-info">
                <h2>Our partners are the cornerstone of our business, and we are dedicated to running a highly effective and efficient organization with a primary focus on achieving our partners' objectives.</h2>
                <div className="cards">
                  <div className="card">
                    <a href="https://www.holobuilder.com"><img src="/images/logo81.svg" alt="" /></a>
                    {/* <p><a href="https://www.holobuilder.com">Holo Builder</a> </p> */}
                  </div>

                  <div className="card">
                    <a href="https://bloomce.com"><img src="/images/logo82.svg" alt="" /></a>
                    {/* <p><a href="https://bloomce.com">Bloom Explorer</a> </p> */}
                  </div>

                  <div className="card">
                    <a href="https://www.faro.com"><img src="/images/logo83.png" alt="" /></a>
                    {/* <p><a href="https://www.faro.com">Faro</a> </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ======================= contact section ======================= */}
        <section className="contact section" id="contact">
          <div className="container flex-center">
            {/* <h1 className="section-title-01">Contact Us</h1>
            <h2 className="section-title-02">Contact Us</h2> */}
            <div className="content">
              <div className="content-left">
                <h2>Still Have Any Question?</h2>
                <p>
                  leave your message here and we will get in touch with
                  you as soon as possible.
                </p>
                <Form
                  name="questionForm"
                  wrapperCol={{ span: 24 }}
                  initialValues={{ remember: true }}
                  onFinish={(e: any) => onFinish(e)}
                  form={form}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="contact-form"
                >
                  <Form.Item
                    name="fullname"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Full Name!",
                      },
                    ]}
                    className="first-row"
                  >
                    <Input placeholder="Full Name" />
                  </Form.Item>
                  <div className="second-row">
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Email!",
                        },
                      ]}
                      className="second-one"

                    >
                      <Input type={"email"} placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      name="subject"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Subject!",
                        },
                      ]}
                      className="second-tow"
                    >
                      <Input placeholder="Subject" />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Message!",
                      },
                    ]}
                    className="thrid-row"
                  >
                    <TextArea rows={6} placeholder="Message" />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={
                      isMobile
                        ? { offset: 12, span: 12 }
                        : { offset: 16, span: 8 }
                    }

                  >
                    <Button
                      type="link"
                      htmlType="submit"
                      loading={isLoading}
                      className="btn"
                    >
                      Send Message &nbsp;<i className="fas fa-paper-plane"></i>
                    </Button>
                  </Form.Item>
                </Form>
              </div>

              <div className="content-right">
                <h2>Contact Us</h2>
                <ul className="contact-list">
                  <li>
                    <h3 className="item-title"><i className="fas fa-envelope"></i> Email</h3>
                    <span><a href="mailto:info@safinnovation.com">info@safinnovation.com</a></span>
                  </li>
                  <li>
                    <h3 className="item-title"><i className="fab fa-whatsapp"></i> Phone</h3>
                    <span><a href="http://wa.me/+971555278224">+971 55 527 8224</a></span>
                    <br />
                    <span><a href="http://wa.me/+966531374724">+966 53 137 4724</a></span>
                  </li>
                </ul>
                <h2>Location</h2>
                <ul className="contact-list">

                  <li>
                    <h3 className="item-title"><i className="fas fa-map-marker-alt"></i>&nbsp; Dubai</h3>
                    <span><a href="https://maps.app.goo.gl/MktbEs2hm2jJDVmS7">Business Bay Grand Side, <br />&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;Bay Avenue, Business Bay, <br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Dubai, United Arab Emirates.</a></span>
                  </li>
                  <li>
                    <h3 className="item-title"><i className="fas fa-map-marker-alt"></i>&nbsp; Canada</h3>
                    <span><a href="https://maps.app.goo.gl/HSMENDpL3RSgCuh87">Rue Saint-Louis, <br />&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;Saint, Laurent, QC H4L 0B5.</a></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container flex-center">

            <div className="find-us">
              <h2>Where to find us?</h2>
              <img src="/images/flag-map.jpg" alt="" />
            </div>

          </div>
        </section>

        {/* ======================= footer section ======================= */}

        <footer>
          <div className="footer-container">
            <div className="about group">
              <div className="logofotter">
                <img src="/images/logo.png" alt="" />
              </div>
              <h3>Construction Solution Services</h3>
              <p>Contractor partners towards a successful project where
                projects are seeing with our innovative services.</p>
            </div>
            <div className="follow group">
              <h3>Follow us on</h3>
              <ul>
                <li><a href="https://www.facebook.com/people/SafInnovation/100086740831039"><i className="fab fa-facebook"></i></a></li>
                <li><a href="https://instagram.com/safinnovation"><i className="fab fa-instagram"></i></a></li>
                <li><a href="https://x.com/safinnovation"><i className="fab fa-x"></i></a></li>
              </ul>
              <ul>
                <li><a href="https://www.linkedin.com/company/safinnovation"><i className="fab fa-linkedin"></i></a></li>
                <li><a href="https://youtube.com/@safinnovation587?si=QcriWFcWUmejbMwV"><i className="fab fa-youtube"></i></a></li>
                <li><a href="https://whatsapp.com/channel/0029Va9K4iuGzzKSdOjpuf2a"><i className="fab fa-whatsapp"></i></a></li>
              </ul>
            </div>
          </div>
          <div className="footer-copyright group flex">
            <h2><a href="http://wa.me/+963938729667">&copy; Rebuilding 2023 by Abdalrhman. All Rights Reserved. </a> </h2>
            <p>Qpact©All Rights Reserved 2022</p>
          </div>
        </footer>

      </div >

    </div >
  );
}

export default App;
